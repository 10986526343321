exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-capacity-building-js": () => import("./../../../src/pages/capacityBuilding.js" /* webpackChunkName: "component---src-pages-capacity-building-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contactUs.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-galleries-js": () => import("./../../../src/pages/galleries.js" /* webpackChunkName: "component---src-pages-galleries-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-media-center-js": () => import("./../../../src/pages/mediaCenter.js" /* webpackChunkName: "component---src-pages-media-center-js" */),
  "component---src-pages-media-center-news-js": () => import("./../../../src/pages/mediaCenter/news.js" /* webpackChunkName: "component---src-pages-media-center-news-js" */),
  "component---src-pages-media-center-youtube-js": () => import("./../../../src/pages/mediaCenter/youtube.js" /* webpackChunkName: "component---src-pages-media-center-youtube-js" */),
  "component---src-pages-partner-institutes-js": () => import("./../../../src/pages/partnerInstitutes.js" /* webpackChunkName: "component---src-pages-partner-institutes-js" */),
  "component---src-pages-team-members-js": () => import("./../../../src/pages/teamMembers.js" /* webpackChunkName: "component---src-pages-team-members-js" */),
  "component---src-pages-test-page-js": () => import("./../../../src/pages/testPage.js" /* webpackChunkName: "component---src-pages-test-page-js" */),
  "component---src-pages-usefull-link-js": () => import("./../../../src/pages/usefullLink.js" /* webpackChunkName: "component---src-pages-usefull-link-js" */),
  "component---src-templates-basic-page-js": () => import("./../../../src/templates/basicPage.js" /* webpackChunkName: "component---src-templates-basic-page-js" */),
  "component---src-templates-gallery-js": () => import("./../../../src/templates/gallery.js" /* webpackChunkName: "component---src-templates-gallery-js" */),
  "component---src-templates-news-article-js": () => import("./../../../src/templates/newsArticle.js" /* webpackChunkName: "component---src-templates-news-article-js" */),
  "component---src-templates-resources-publications-js": () => import("./../../../src/templates/resourcesPublications.js" /* webpackChunkName: "component---src-templates-resources-publications-js" */)
}

